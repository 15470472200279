/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        var windowWidth = $(window).width();

        $('.anchor-text').on('click',function (e) {
          e.preventDefault();
          var content = $(this).data('obj');

          $('.inline-content').hide();
          $('.inline-content').animate({'opacity':0,'display':'block'},100);
          
          if($(this).hasClass('selected')){
            $("#"+content).animate({'opacity':0,'display':'none'},100);
            $("#"+content).hide();
            $(this).removeClass('selected');
            $('.anchor-text').removeClass('selected');
            $('.page__banner').fadeIn();
          } else {
            $('.anchor-text').removeClass('selected');
            $(this).addClass('selected');
            $("#"+content).animate({'opacity':1,'display':'block'},100);
            $("#"+content).show();
            $('.page__banner').fadeOut();
             
          }

          if(parseInt(windowWidth) < 680){
            $('.remove-on-open').hide();
          }
          $('.close-inline').show();
        });

        $('.logo-max').on('click',function(){
          $('.inline-content').hide();
          $('.inline-content').animate({'opacity':0,'display':'block'},100);
          $('.page__banner').fadeIn();
          $('.anchor-text').removeClass('selected');
        });

        //
        $('.register-btn').on('click',function(){
          if($('.form-popover').hasClass('open')){
            $('.form-popover').removeClass('open');
          }else {
            $('.form-popover').addClass('open');
          }
        });

        $('.close-form').on('click',function(){
          $('.form-popover').removeClass('open');
        });

        // 
        $('input[type="checkbox"]').on('change', function() {
           $('input[type="checkbox"]').not(this).prop('checked', false);
        });

        // CLOSE INLINE CONTENT
        $('.close-inline').on('click',function () {
          $('.inline-content').hide();
          $('.remove-on-open').show();
          $('.close-inline').hide();
          $('.sm-address').show();
          $('.page__banner').fadeIn();
        });

        // HOMEPAGE SUB NAV
        $('.open-sub-nav').on('click',function(e){
          e.preventDefault();
        });
        $('.open-sub-nav').on('mouseover',function(e){
           $(this).parent().find('.sub-nav').fadeIn();
        });
        $('.sub-nav').on('mouseleave',function(e){
           $('.sub-nav').fadeOut();
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
